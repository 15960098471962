import './MenuPage.css'

import ScrollAnimation from '../../components/ScrollAnimation/ScrollAnimation'
import AnimatedPage from '../../components/AnimatedPage/AnimatedPage'
import LoadedImage from '../../components/LoadedImage/LoadedImage'

// ------------------------BURGRE------------------------

import bossBosov from '../../img/menuImages/4_small.jpg'
import bossBosov_blur from '../../img/menuImages/4_blur.jpg'

import alCapone from '../../img/menuImages/12_small.jpg'
import alCapone_blur from '../../img/menuImages/12_blur.jpg'

import pablo from '../../img/menuImages/5_small.jpg'
import pablo_blur from '../../img/menuImages/5_blur.jpg'

import spicyVito from '../../img/menuImages/6_small.jpg'
import spicyVito_blur from '../../img/menuImages/6_blur.jpg'

import royalCrown from '../../img/menuImages/9_small.jpg'
import royalCrown_blur from '../../img/menuImages/9_blur.jpg'

import doctorLucky from '../../img/menuImages/10_small.jpg'
import doctorLucky_blur from '../../img/menuImages/10_blur.jpg'

import roastBeef from '../../img/menuImages/14_small.jpg'
import roastBeef_blur from '../../img/menuImages/14_blur.jpg'

import lotus from '../../img/menuImages/3_small.jpg'
import lotus_blur from '../../img/menuImages/3_blur.jpg'

import godfather from '../../img/menuImages/2_small.jpg'
import godfather_blur from '../../img/menuImages/2_blur.jpg'

import tower from '../../img/menuImages/8_small.jpg'
import tower_blur from '../../img/menuImages/8_blur.jpg'

import johnyChicken from '../../img/menuImages/7_small.jpg'
import johnyChicken_blur from '../../img/menuImages/7_blur.jpg'

import francescoVega from '../../img/menuImages/13_small.jpg'
import francescoVega_blur from '../../img/menuImages/13_blur.jpg'

import deLuxe from '../../img/menuImages/11_small.jpg'
import deLuxe_blur from '../../img/menuImages/11_blur.jpg'

// ------------------DETSKE MENU-----------------

import babyBurger from '../../img/menuImages/1_small.jpg'
import babyBurger_blur from '../../img/menuImages/1_blur.jpg'

import dinosaurus from '../../img/menuImages/23_small.jpg'
import dinosaurus_blur from '../../img/menuImages/23_blur.jpg'

// -------------------------------------------------

// -----------------------BOSS DOGY------------------------

import bossDog from '../../img/menuImages/42_small.jpg'
import bossDog_blur from '../../img/menuImages/42_blur.jpg'

import woodieBoss from '../../img/menuImages/41_small.jpg'
import woodieBoss_blur from '../../img/menuImages/41_blur.jpg'

// -----------------SALATY------------------------

import balsamicoChicken from '../../img/menuImages/29_small.jpg'
import balsamicoChicken_blur from '../../img/menuImages/29_blur.jpg'

import grilCamembert from '../../img/menuImages/30_small.jpg'
import grilCamembert_blur from '../../img/menuImages/30_blur.jpg'

import roastBeefSalat from '../../img/menuImages/32_small.jpg'
import roastBeefSalat_blur from '../../img/menuImages/32_blur.jpg'

import krevetovySalat from '../../img/menuImages/31_small.jpg'
import krevetovySalat_blur from '../../img/menuImages/31_blur.jpg'

// -----------------------TANIERE-------------------------

import tanierOne from '../../img/menuImages/25_small.jpg'
import tanierOne_blur from '../../img/menuImages/25_blur.jpg'
import tanierTwo from '../../img/menuImages/26_small.jpg'
import tanierTwo_blur from '../../img/menuImages/26_blur.jpg'
import tanierThree from '../../img/menuImages/27_small.jpg'
import tanierThree_blur from '../../img/menuImages/27_blur.jpg'
import tanierFour from '../../img/menuImages/28_small.jpg'
import tanierFour_blur from '../../img/menuImages/28_blur.jpg'

// --------------------------------------------------

import staekoveHranolky from '../../img/menuImages/15_small.jpg'
import staekoveHranolky_blur from '../../img/menuImages/15_blur.jpg'
import batatoveHranolky from '../../img/menuImages/16_small.jpg'
import batatoveHranolky_blur from '../../img/menuImages/16_blur.jpg'
import batatoveHranolkySchedarom from '../../img/menuImages/17_small.jpg'
import batatoveHranolkySchedarom_blur from '../../img/menuImages/17_blur.jpg'

import palacinky from '../../img/menuImages/39_small.jpg'
import palacinky_blur from '../../img/menuImages/39_blur.jpg'
import sufle from '../../img/menuImages/40_small.jpg'
import sufle_blur from '../../img/menuImages/40_blur.jpg'

import onionBomb from '../../img/menuImages/20_small.jpg'
import onionBomb_blur from '../../img/menuImages/20_blur.jpg'
import krevetyVcesticku from '../../img/menuImages/21_small.jpg'
import krevetyVcesticku_blur from '../../img/menuImages/21_blur.jpg'
import kuracieStripsy from '../../img/menuImages/22_small.jpg'
import kuracieStripsy_blur from '../../img/menuImages/22_blur.jpg'
import grilovanaKukurica from '../../img/menuImages/24_small.jpg'
import grilovanaKukurica_blur from '../../img/menuImages/24_blur.jpg'
import omacky from '../../img/menuImages/33_small.jpg'
import omacky_blur from '../../img/menuImages/33_blur.jpg'

import menuPageBG from '../../img/menuImages/4_resized.jpg'
import menuPageBG_blur from '../../img/menuImages/4_blur.jpg'
import { Helmet } from 'react-helmet'

const MenuPage = ({ locale }) => {
    const idBurgre = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12'
    ]
    const titlesBurgre = [
        'PABLO',
        'AL CAPONE',
        'ROYAL CROWN',
        'SPICY VITO',
        'ROAST BEEF',
        'DOCTOR LUCKY',
        'GODFATHER',
        'LOTUS',
        'JOHNY CHICKEN',
        'TOWER',
        'FRANCESCO VEGA',
        'BOSS BOSSOV',
        'DE LUXE'
    ]
    const pricesBurgre = [
        '10.90€',
        '10.70€',
        '11.80€',
        '10.90€',
        '15.90€',
        '11.30€',
        '10.90€',
        '10.90€',
        '10.90€',
        '12.80€',
        '10.90€',
        '18.90€',
        '11.30€'
    ]
    const descriptionsBurgreSK = [
        'Žemľa, aioli omáčka, polníček, mozzarella, syr raclettte, 180g hovädzie mäso, 2x pražená slaninka, paradajka, medovo-horčicová omáčka, ementál, al:1,3,7,1',
        'Žemľa, kyslá uhorka, 2x cheddar, BBQ omáčka, 2x pražená slaninka, 180g trhané pulled pork, karamelizovaná cibuľka, al:1,3,7,1',
        'Žemľa, aioli omáčka, polníček, 100ml tekutý cheddar, zemiaková placka, 180g hovädzie mäso, mozzarella, červená fazuľa, BBQ majo, al:1,3,7,1',
        'Žemľa, habanero omáčka, polníček, 180g hovädzie mäso, 2x cheddar, paradajka, jalapeňo, čerstvá cibuľa, chilli paprička, pikantná red omáčka, al:1,3,7,1',
        'Žemľa, domáca kôprová omáčka, 200g roast beefu, červená cibuľka, 2x cheddar, polníček, al:1,3,7,1',
        'Žemľa, domáca mayo, 180g hovädzie mäso, restované šampiňóny s cibuľkou, syrraclette, BBQ omáčka, cheddarová injekcia 40ml, al:1,3,7,1',
        'Žemľa, slaninová omáčka, 180g hovädzie mäso, 2x syr cheddar, 2x pražená slaninka, BBQ omáčka, volské oko, polníček, al:1,3,7,1',
        'Žemľa, mayo, 2x cheddar, lotus krém, sušienka lotus, 180g hovädzie mäso, 2x pražená slaninka, al:1,3,7,1',
        'Žemľa, vyprážané kuracie prsia 1ks, domáca mayo, 2x pražená slaninka, polníček, ružový zázvor, mozzarella, sweet chilli, al:1,3,7,1',
        'Žemľa, polníček, aioliomáčka, 180g hovädzie mäso, kukurica,1x cheddar, 2x slaninka, hľuzovková omáčka, 1x mozzarella, fritovaný zemiak, al:1,3,7,1',
        'Žemľa, domáca mayo, zemiaková placka, brusnicový džem, vypražaný camembert, polníček, paradajka, mayo, al:1,3,',
        'Žemľa, BBQ omáčka, polníček, paradajka, kyslá uhorka, 4x pražená slaninka, 2x zemiaková placka, karamelizovaná cibuľka, 2x 180g hovädzie mäso, aioli omáčka, 2x cheddar, paradajka, cheddar, uhlík, al:1,3,7,1',
        'Žemľa, krevety v tempure 4ks, salsa, tekutý cheddar, riasa wakame, aioli omáčka, 180g hovädzie mäso, al:1,3,4,'
    ]

    const descriptionsBurgreEN = [
        'Bun, aioli sauce, lettuce, mozzarella, raclette cheese, 180g beef meat, 2x roasted bacon, tomato, honey-mustard sauce, emmental, al:1,3,7,1',
        'Bun, pickled cucumber, 2x cheddar, BBQ sauce, 2x roasted bacon, 180g pulled pork, caramelized onion, al:1,3,7,1',
        'Bun, aioli sauce, lettuce, 100ml liquid cheddar, potato pancake, 180g beef meat, mozzarella, red beans, BBQ mayo, al:1,3,7,1',
        'Bun, habanero sauce, lettuce, 180g beef meat, 2x cheddar, tomato, jalapeño, fresh onion, chilli pepper, spicy red sauce, al:1,3,7,1',
        'Bun, homemade dill sauce, 200g roast beef, red onion, 2x cheddar, lettuce, al:1,3,7,1',
        'Bun, homemade mayo, 180g beef meat, roasted mushrooms with onion, raclette cheese, BBQ sauce, 40ml cheddar injection, al:1,3,7,1',
        'Bun, bacon sauce, 180g beef meat, 2x cheddar cheese, 2x roasted bacon, BBQ sauce, sunny-side-up egg, lettuce, al:1,3,7,1',
        'Bun, mayo, 2x cheddar, lotus cream, lotus biscuit, 180g beef meat, 2x roasted bacon, al:1,3,7,1',
        'Bun, fried chicken breast 1pc, homemade mayo, 2x roasted bacon, lettuce, pickled ginger, mozzarella, sweet chilli, al:1,3,7,1',
        'Bun, lettuce, aioli sauce, 180g beef meat, corn,1x cheddar, 2x bacon, root vegetable sauce, 1x mozzarella, fried potato, al:1,3,7,1',
        'Bun, homemade mayo, potato pancake, cranberry jam, fried camembert, lettuce, tomato, mayo, al:1,3,',
        'Bun, BBQ sauce, lettuce, tomato, pickled cucumber, 4x roasted bacon, 2x potato pancake, caramelized onion, 2x 180g beef meat, aioli sauce, 2x cheddar, tomato, cheddar, charcoal, al:1,3,7,1',
        'Bun, tempura prawns 4pcs, salsa, liquid cheddar, wakame seaweed, aioli sauce, 180g beef meat, al:1,3,4,'
    ]

    const descriptionsBurgre =
        locale === 'sk' ? descriptionsBurgreSK : descriptionsBurgreEN

    const imagesBurgre = [
        pablo,
        alCapone,
        royalCrown,
        spicyVito,
        roastBeef,
        doctorLucky,
        godfather,
        lotus,
        johnyChicken,
        tower,
        francescoVega,
        bossBosov,
        deLuxe
    ]
    const images_blurBurgre = [
        pablo_blur,
        alCapone_blur,
        royalCrown_blur,
        spicyVito_blur,
        roastBeef_blur,
        doctorLucky_blur,
        godfather_blur,
        lotus_blur,
        johnyChicken_blur,
        tower_blur,
        francescoVega_blur,
        bossBosov_blur,
        deLuxe_blur
    ]

    // ---------------------------------------------------------------------

    const idSalaty = ['0', '1', '2', '3']
    const titlesSalatySK = [
        'BALSAMICO CHICKEN',
        'GRIL CAMEMBERT',
        'ROASTBEEF ŠALÁT',
        'KREVETOVÝ ŠALÁT'
    ]
    const titlesSalatyEN = [
        'BALSAMICO CHICKEN',
        'GRIL CAMEMBERT',
        'ROASTBEEF SALAD',
        'SHRIMP SALAD'
    ]
    const titlesSalaty = locale === 'sk' ? titlesSalatySK : titlesSalatyEN
    const pricesSalaty = ['8.90€', '8.90€', '9.90€', '8.90€']
    const descriptionsSalatySK = [
        'mix listovej zeleniny, cherry paradajky, uhorka, vyprážané kuracie prsia 1ks, balsamická redukcia,olivový olej, al:1,3,12',
        'mix listovej zeleniny, cherry paradajky, mrkva, biela fazuľka v tomatovom náleve, grilovaný camembert,olivový olej, slaninka, al:7',
        'mix listovej zeleniny, cherry paradajky, červená cibuľka, roastbeef b sladko-slanom laku, parmezán, al:6,7',
        'mix listovej zeleniny, cherry paradajky, sladká kukurica, krevety v cestíčku, medovo-horčicová omáčka, citrón, al:1,3,7,10'
    ]

    const descriptionsSalatyEN = [
        'mix of leafy greens, cherry tomatoes, cucumber, fried chicken breast 1pc, balsamic reduction, olive oil, al:1,3,12',
        'mix of leafy greens, cherry tomatoes, carrot, white beans in tomato sauce, grilled camembert, olive oil, bacon, al:7',
        'mix of leafy greens, cherry tomatoes, red onion, roast beef in sweet and salty glaze, parmesan, al:6,7',
        'mix of leafy greens, cherry tomatoes, sweet corn, shrimps in batter, honey-mustard sauce, lemon, al:1,3,7,10'
    ]

    const descriptionsSalaty =
        locale === 'sk' ? descriptionsSalatySK : descriptionsSalatyEN

    const imagesSalaty = [
        balsamicoChicken,
        grilCamembert,
        roastBeefSalat,
        krevetovySalat
    ]
    const images_blurSalaty = [
        balsamicoChicken_blur,
        grilCamembert_blur,
        roastBeefSalat_blur,
        krevetovySalat_blur
    ]

    return (
        <>
            <Helmet>
                <title>Boss Burger Prešov Menu</title>
            </Helmet>
            <div className='menuPage'>
                <div className='menuTop'>
                    <LoadedImage
                        src={[menuPageBG]}
                        blur={menuPageBG_blur}
                        alt={'boss bossov burger'}
                    />
                    <AnimatedPage>
                        <h1>MENU</h1>
                    </AnimatedPage>
                </div>
                <div className='menuList'>
                    <div id='burgre'>
                        <h2>{locale === 'sk' ? 'BURGRE' : 'BURGERS'}</h2>
                        <div className='itemList'>
                            {idBurgre.map((i) => {
                                return (
                                    <div key={idBurgre[i]} className='item'>
                                        <ScrollAnimation
                                            style={
                                                i % 2 === 0
                                                    ? 'toRight'
                                                    : 'toLeft'
                                            }
                                        >
                                            <LoadedImage
                                                src={[imagesBurgre[i]]}
                                                blur={[images_blurBurgre[i]]}
                                                alt={
                                                    titlesBurgre[i] + ' burger'
                                                }
                                            />
                                        </ScrollAnimation>
                                        <ScrollAnimation style={'up'}>
                                            <h3>{titlesBurgre[i]}</h3>
                                            <p className='price'>
                                                {pricesBurgre[i]}
                                            </p>
                                            <p>{descriptionsBurgre[i]}</p>
                                        </ScrollAnimation>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='bossWoodie'>
                    {/* <div className='section'>
                        <ScrollAnimation style='toRight'>
                            <h2>BOSS DOG</h2>
                        </ScrollAnimation>
                        <ScrollAnimation style='toRight'>
                            <div className='bossDogsWrapper'>
                                <div className='bossDog'>
                                    <div className='top'>
                                        <h3>BACON(300G)</h3>
                                        <p className='price'>7.90€</p>
                                    </div>
                                    <p className='bottom'>
                                        {locale === 'sk'
                                            ? 'Domáci rožok, grilovaná párka, kyslá uhorka, slaninová omáčka, kečup, slaninové lupene, tekutý chedar Alergény:1,3,7'
                                            : 'Home bun, grilled sausage, sour pickle, bacon sauce, ketchup, bacon chips, liquid cheddar Allergens: 1,3,7'}
                                    </p>
                                </div>
                                <div className='bossDog'>
                                    <div className='top'>
                                        <h3>HOT PORK(300g)</h3>
                                        <p className='price'>7.90€</p>
                                    </div>
                                    <p className='bottom'>
                                        {locale === 'sk'
                                            ? 'Domáci rožok, bravčové trhané puled pork, mayo, BQ omáčka, jalapeňo, červená cibuľka, tekutý chedar Alergény:1,3,'
                                            : 'Home bun, pulled pork, mayo, BBQ sauce, jalapeño, red onion, liquid cheddar Allergens: 1,3,'}
                                    </p>
                                </div>
                                <div className='bossDog'>
                                    <div className='top'>
                                        <h3>TATO(300g)</h3>
                                        <p className='price'>7.90€</p>
                                    </div>
                                    <p className='bottom'>
                                        {locale === 'sk'
                                            ? 'Domáci rožok, grilovaná párka, paradajky, tatárska omáčka, kečup, tekutý chedar, zemiakové seno Alergény:1,3,7'
                                            : 'Home bun, grilled sausage, tomatoes, tartar sauce, ketchup, liquid cheddar, potato hay (likely meaning potato sticks or crisps) Allergens: 1,3,7'}
                                    </p>
                                </div>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation style='toRight'>
                            <LoadedImage
                                src={[bossDog]}
                                blur={bossDog_blur}
                                alt={'boss dog'}
                            />
                        </ScrollAnimation>
                    </div> */}
                    <div className='section right'>
                        <ScrollAnimation style='toLeft'>
                            <h2>WOODIE BOSS</h2>
                        </ScrollAnimation>
                        <ScrollAnimation style='toLeft'>
                            <div className='bossDogsWrapper'>
                                <div className='bossDog'>
                                    <div className='top'>
                                        <h3>ROAST BEEF WOODIE</h3>
                                        <p className='price'>7.90€</p>
                                    </div>
                                    <p className='bottom'>
                                        {locale === 'sk'
                                            ? 'Kváskový chlieb, 100g roastbeef, kôprová omáčka, kyslá uhorka Alergény:1,3,7'
                                            : 'Sourdough bread, 100g roast beef, dill sauce, sour pickle Allergens: 1,3,7'}
                                    </p>
                                </div>
                                <div className='bossDog'>
                                    <div className='top'>
                                        <h3>PULLED WOODIE</h3>
                                        <p className='price'>7.90€</p>
                                    </div>
                                    <p className='bottom'>
                                        {locale === 'sk'
                                            ? 'Kváskový chlieb, bravčové trhané pulled pork, mayo, červená cibuľka, cheddar Alergény:1,3,7'
                                            : 'Sourdough bread, pulled pork, mayo, red onion, cheddar Allergens: 1,3,7'}
                                    </p>
                                </div>
                                <div className='bossDog'>
                                    <div className='top'>
                                        <h3>PESTO WOODIE</h3>
                                        <p className='price'>7.90€</p>
                                    </div>
                                    <p className='bottom'>
                                        {locale === 'sk'
                                            ? 'Kváskový chlieb, vyprážané kuracie prsia, pesto mayo, paradajky, cheddar Alergény:1,3,7'
                                            : 'Sourdough bread, fried chicken breast, pesto mayo, tomatoes, cheddar Allergens: 1,3,7'}
                                    </p>
                                </div>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation style='toLeft'>
                            <LoadedImage
                                src={[woodieBoss]}
                                blur={woodieBoss_blur}
                                alt={'boss dog'}
                            />
                        </ScrollAnimation>
                    </div>
                </div>
                {/* <div className='prilohyOmacky'>
                    <div className='prilohy'>
                        <h2>{locale === 'sk' ? 'PRÍLOHY' : 'SIDE DISHES'}</h2>
                        <ScrollAnimation style={'toRight'} classname='content'>
                            <div className='left'>
                                <div className='riadok'>
                                    <p>
                                        1.{' '}
                                        {locale === 'sk'
                                            ? 'RUŽOVÝ ZAZVOR'
                                            : 'PINK GINGER'}{' '}
                                        20G
                                    </p>
                                    <p>0,90€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        2.{' '}
                                        {locale === 'sk'
                                            ? 'ČERVENÁ CIBUĽA'
                                            : 'RED ONION'}{' '}
                                        20G
                                    </p>
                                    <p>0,90€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        3.{' '}
                                        {locale === 'sk'
                                            ? 'JALAPEŇO'
                                            : 'JALAPEÑO '}{' '}
                                        20G
                                    </p>
                                    <p>0,90€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        4.{' '}
                                        {locale === 'sk'
                                            ? 'RIASA WAKAME'
                                            : 'SEAWEED SALAD (WAKAME)'}{' '}
                                        20G
                                    </p>
                                    <p>0,90€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        5.{' '}
                                        {locale === 'sk'
                                            ? 'ZEMIAKOVÁ PLACKA '
                                            : 'POTATO PANCAKE'}{' '}
                                        1KS
                                    </p>
                                    <p>1,00€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        6.{' '}
                                        {locale === 'sk'
                                            ? 'HOVÄDZIE MÄSO'
                                            : 'BEEF MEAT'}
                                        180G
                                    </p>
                                    <p>3,50€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        7.{' '}
                                        {locale === 'sk'
                                            ? 'RESTOVANÉ ŠAMIŇÓNY NA CIBUĽKE'
                                            : 'ROASTED CHAMPIGNONS ON ONION'}{' '}
                                        20G
                                    </p>
                                    <p>0,90€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        8.{' '}
                                        {locale === 'sk'
                                            ? 'PARADAJKA'
                                            : 'TOMATO'}{' '}
                                        20G
                                    </p>
                                    <p>0,90€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        9.{' '}
                                        {locale === 'sk'
                                            ? 'POLNÍČEK'
                                            : 'Field Mushroom'}{' '}
                                        20G
                                    </p>
                                    <p>0,90€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        10.{' '}
                                        {locale === 'sk'
                                            ? 'BIELA FAZUĽA'
                                            : 'WHITE BEANS'}{' '}
                                        20G
                                    </p>
                                    <p>0,90€</p>
                                </div>
                            </div>
                            <div className='right'>
                                <div className='riadok'>
                                    <p>
                                        11.{' '}
                                        {locale === 'sk'
                                            ? 'CHILLI PAPRIČKA 1KS'
                                            : 'CHILI PEPPER 1PC'}
                                    </p>
                                    <p>0,90€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        12.{' '}
                                        {locale === 'sk'
                                            ? 'VOLSKÉ OKO 1KS'
                                            : 'SUNNY-SIDE-UP EGG 1PC'}
                                    </p>
                                    <p>1,50€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        13.{' '}
                                        {locale === 'sk'
                                            ? 'TRHANÉ PULLED PORK'
                                            : 'PULLED PORK'}
                                        180G
                                    </p>
                                    <p>2,90€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        14.{' '}
                                        {locale === 'sk'
                                            ? 'KYSLÁ UHORKA'
                                            : 'SOUR PICKLE'}{' '}
                                        20G
                                    </p>
                                    <p>0,90€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        15.{' '}
                                        {locale === 'sk'
                                            ? 'KARAMELIZOVANÁ CIBUĽA'
                                            : 'CARAMELIZED ONION'}{' '}
                                        20G
                                    </p>
                                    <p>0,90€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        16.{' '}
                                        {locale === 'sk'
                                            ? 'SLADKÁ KUKURICA'
                                            : 'SWEET CORN'}{' '}
                                        20G
                                    </p>
                                    <p>0,90€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        17.{' '}
                                        {locale === 'sk'
                                            ? 'SLANINKA PRAŽENÁ 2KS'
                                            : 'ROASTED BACON 2PC'}
                                    </p>
                                    <p>1,50€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        18.{' '}
                                        {locale === 'sk'
                                            ? 'VYPRÁŽANÝ CAMEMBERT 1KS'
                                            : 'FRIED CAMEMBERT 1PC'}
                                    </p>
                                    <p>2,50€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        19.{' '}
                                        {locale === 'sk'
                                            ? 'VYPRÁŽANÉ KURACIE PRSIA 1KS'
                                            : 'FRIED CHICKEN BREAST 1PC'}
                                    </p>
                                    <p>3,20€</p>
                                </div>
                                <div className='riadok'>
                                    <p>
                                        20.{' '}
                                        {locale === 'sk'
                                            ? 'SYR/MOZZARELLA, RECLETTE, CHEDDAR, EMENTÁL 1KS'
                                            : 'CHEESE/MOZZARELLA, RACLETTE, CHEDDAR, EMMENTAL 1PC'}
                                    </p>
                                    <p>1,20€</p>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className='omacky'>
                        <h2>{locale === 'sk' ? 'OMÁČKY' : 'SAUCES'}</h2>
                        <LoadedImage
                            src={[omacky]}
                            blur={omacky_blur}
                            alt={'omáčky'}
                        />
                        <p>
                            {locale === 'sk'
                                ? 'tatárska - aioli - bbq - bbq mayo - habanero - slaninová- sweet chilli - mayo - domáca mayo red pikant - medovo-horčicová - salsa - chilli mayo - kečup - hamburgerová bryndzová - hľuzovka - mexico - pesto mayo - curry - kôprová'
                                : 'tartar-aioli-bbq-bbq mayo-habanero-bacon-sweet chilli-mayo-homemade spicy red mayo-honey mustard-salsa-chilli mayo-ketchup-hamburger-sheep cheese-truffle-mexico-pesto mayo-curry-dill'}
                        </p>
                        <ScrollAnimation style={'toLeft'} classname='content'>
                            <div>
                                <LoadedImage
                                    src={[krevetyVcesticku]}
                                    blur={krevetyVcesticku_blur}
                                    alt={'krevety v cestíčku'}
                                />
                                <div>
                                    <p>
                                        {locale === 'sk'
                                            ? 'Krevety v cestíčku 4ks(1,3,4)'
                                            : 'Shrimp in batter 4pcs(1,3,4)'}
                                    </p>
                                    <p>3.50€</p>
                                </div>
                            </div>
                            <div>
                                <LoadedImage
                                    src={[onionBomb]}
                                    blur={onionBomb_blur}
                                    alt={'onion bomb'}
                                />
                                <div>
                                    <p>
                                        {locale === 'sk'
                                            ? 'Onion Bomb1ks(1,3,7)'
                                            : 'Onion Bomb 1pc(1,3,7)'}
                                    </p>
                                    <p>3.00€</p>
                                </div>
                            </div>
                            <div>
                                <LoadedImage
                                    src={[kuracieStripsy]}
                                    blur={kuracieStripsy_blur}
                                    alt={'kuracie stripsy'}
                                />
                                <div>
                                    <p>
                                        {locale === 'sk'
                                            ? 'Kuracie stripsy 3ks(1,3)'
                                            : 'Chicken strips 3pcs(1,3)'}
                                    </p>
                                    <p>4.50€</p>
                                </div>
                            </div>
                            <div>
                                <LoadedImage
                                    src={[grilovanaKukurica]}
                                    blur={grilovanaKukurica_blur}
                                    alt={'grilovaná kukurica'}
                                />
                                <div>
                                    <p>
                                        {locale === 'sk'
                                            ? 'Grilovaná kukurica 2ks'
                                            : 'Grilled corn 2pcs'}
                                    </p>
                                    <p>3.50€</p>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div> */}
                <div className='snacky'>
                    <h2>
                        {locale === 'sk'
                            ? 'SNACKY PRE BOSSOV'
                            : 'SNACKS FOR BOSSES'}
                    </h2>
                    <div className='snackyList'>
                        <ScrollAnimation style={'toRight'}>
                            {locale === 'sk'
                                ? 'STEAKOVE HRANOLKY 150G'
                                : 'STEAK FRIES 150G'}{' '}
                            <br /> <span>2.80€</span>
                        </ScrollAnimation>
                        <ScrollAnimation style={'up'}>
                            <LoadedImage
                                src={[staekoveHranolky]}
                                blur={staekoveHranolky_blur}
                                alt={
                                    locale === 'sk'
                                        ? 'Staekové hranolky'
                                        : 'Steak fries'
                                }
                            />
                        </ScrollAnimation>
                        <ScrollAnimation style={'toLeft'}>
                            {locale === 'sk'
                                ? 'STEAKOVE HRANOLKY S PARMEZÁNOM 170G al:7'
                                : 'STEAK FRIES WITH PARMESAN 170G al:7'}{' '}
                            <br /> <span>3.50€</span>
                        </ScrollAnimation>
                        <ScrollAnimation style={'toRight'}>
                            {locale === 'sk'
                                ? 'BATATOVÉ HRANOLKY 150G'
                                : 'SWEET POTATO FRIES 150G'}{' '}
                            <br /> <span>3.80€</span>
                        </ScrollAnimation>
                        <ScrollAnimation style={'up'}>
                            <LoadedImage
                                src={[batatoveHranolky]}
                                blur={batatoveHranolky_blur}
                                alt={
                                    locale === 'sk'
                                        ? 'Batatové hranolky'
                                        : 'Sweet potato fries'
                                }
                            />
                        </ScrollAnimation>
                        <ScrollAnimation style={'toLeft'}>
                            {locale === 'sk'
                                ? 'BATATOVÉ HRANOLKY S PARMEZÁNOM 170G al:7'
                                : 'SWEET POTATO FRIES WITH PARMESAN 170G al:7'}{' '}
                            <br /> <span>4.50€</span>
                        </ScrollAnimation>
                        <ScrollAnimation style={'toRight'}>
                            {locale === 'sk'
                                ? 'STEAKOVÉ HRANOLKY S TEKUTÝM CHEDDAROM A SLANINKOU 200G al:7'
                                : 'STEAK FRIES WITH LIQUID CHEDDAR AND BACON 200G al:7'}{' '}
                            <br /> <span>4.50€</span>
                        </ScrollAnimation>
                        <ScrollAnimation style={'up'}>
                            <LoadedImage
                                src={[batatoveHranolkySchedarom]}
                                blur={batatoveHranolkySchedarom_blur}
                                alt={
                                    locale === 'sk'
                                        ? 'Batatové hranolky s cheddarom'
                                        : 'Sweet potato fries with cheddar'
                                }
                            />
                        </ScrollAnimation>
                        <ScrollAnimation style={'toLeft'}>
                            {locale === 'sk'
                                ? 'BATATOVÉ HRANOLKY S TEKUTÝM CHEDDAROM A SLANINKOU 200G al:7'
                                : 'SWEET POTATO FRIES WITH LIQUID CHEDDAR AND BACON 200G al:7'}{' '}
                            <br /> <span>5.50€</span>
                        </ScrollAnimation>
                    </div>
                </div>

                <div className='menuList'>
                    <div id='salaty'>
                        <h2>{locale === 'sk' ? 'ŠALÁTY' : 'SALADS'}</h2>
                        <div className='itemList'>
                            {idSalaty.map((i) => {
                                return (
                                    <div key={idSalaty[i]} className='item'>
                                        <ScrollAnimation
                                            style={
                                                i % 2 === 0
                                                    ? 'toRight'
                                                    : 'toLeft'
                                            }
                                        >
                                            <LoadedImage
                                                src={[imagesSalaty[i]]}
                                                blur={[images_blurSalaty[i]]}
                                                alt={titlesSalaty[i]}
                                            />
                                        </ScrollAnimation>
                                        <ScrollAnimation style={'up'}>
                                            <h3>{titlesSalaty[i]}</h3>
                                            <p className='price'>
                                                {pricesSalaty[i]}
                                            </p>
                                            <p>{descriptionsSalaty[i]}</p>
                                        </ScrollAnimation>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div id='taniere'>
                        <h2>
                            {locale === 'sk'
                                ? 'TANIER PRE BOSSOV'
                                : 'PLATE FOR BOSSES'}
                        </h2>
                        <div className='itemList'>
                            <div className='item'>
                                <ScrollAnimation style={'toRight'}>
                                    <LoadedImage
                                        src={[tanierOne]}
                                        blur={tanierOne_blur}
                                        alt={'TANIER PRE BOSSOV'}
                                    />
                                </ScrollAnimation>
                                <ScrollAnimation style={'up'}>
                                    <p className='price'>8.90€</p>
                                    <p>
                                        {locale === 'sk'
                                            ? 'Steakove hranolky s hovädzím mäsom, hranolky, hovädzie mäso, tekutý cheddar, fritovaná cibuľka, petržlen 320g (1,7)'
                                            : 'Steak fries with beef, fries, beef, liquid cheddar, fried onion, parsley 320g (1,7)'}
                                    </p>
                                </ScrollAnimation>
                            </div>
                            <div className='item'>
                                <ScrollAnimation style={'toLeft'}>
                                    <LoadedImage
                                        src={[tanierTwo]}
                                        blur={tanierTwo_blur}
                                        alt={'TANIER PRE BOSSOV'}
                                    />
                                </ScrollAnimation>
                                <ScrollAnimation style={'up'}>
                                    <p className='price'>8.90€</p>
                                    <p>
                                        {locale === 'sk'
                                            ? 'Steakove hranolky s trhaným pulled pork, hranolky, trhané pulled pork, bryndzová omáčka, červená cibuľka, petržlen 320g (1,7)'
                                            : 'Steak fries with shredded pulled pork, fries, shredded pulled pork, sheep cheese sauce, red onion, parsley 320g (1,7)'}
                                    </p>
                                </ScrollAnimation>
                            </div>
                            <div className='item'>
                                <ScrollAnimation style={'toRight'}>
                                    <LoadedImage
                                        src={[tanierThree]}
                                        blur={tanierThree_blur}
                                        alt={'TANIER PRE BOSSOV'}
                                    />
                                </ScrollAnimation>
                                <ScrollAnimation style={'up'}>
                                    <p className='price'>9.90€</p>
                                    <p>
                                        {locale === 'sk'
                                            ? 'Batatové hranolky s hovädzím mäsom, hranolky, hovädzie mäso, tekuty cheddar, fritovaná cibuľka, petržlen 320g (1,7)'
                                            : 'Sweet potato fries with beef, fries, beef, liquid cheddar, fried onion, parsley 320g (1,7)'}
                                    </p>
                                </ScrollAnimation>
                            </div>
                            <div className='item'>
                                <ScrollAnimation style={'toLeft'}>
                                    <LoadedImage
                                        src={[tanierFour]}
                                        blur={tanierFour_blur}
                                        alt={'TANIER PRE BOSSOV'}
                                    />
                                </ScrollAnimation>
                                <ScrollAnimation style={'up'}>
                                    <p className='price'>9.90€</p>
                                    <p>
                                        {locale === 'sk'
                                            ? 'Batatové hranolky s hovädzím mäsom, hranolky, trhané pulled pork, bryndzová omáčka, červená cibuľka, petržlen 320g (1,7)'
                                            : 'Sweet potato fries with beef, fries, shredded pulled pork, sheep cheese sauce, red onion, parsley 320g (1,7)'}
                                    </p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2>
                            {locale === 'sk' ? 'DETSKÉ MENU' : "KIDS' MENU"}
                        </h2>
                        <div className='itemList'>
                            <div className='item'>
                                <ScrollAnimation style={'toRight'}>
                                    <LoadedImage
                                        src={[dinosaurus]}
                                        blur={dinosaurus_blur}
                                        alt={'DINOSAURUS'}
                                    />
                                </ScrollAnimation>
                                <ScrollAnimation style={'up'}>
                                    <h3>
                                        {locale === 'sk'
                                            ? 'Dinosaurus'
                                            : 'Dinosaur'}
                                    </h3>
                                    <p className='price'>6.90€</p>
                                    <p>
                                        {locale === 'sk'
                                            ? '(90g, 150g) S hranolkami (1,3)'
                                            : '(90g, 150g) With fries (1,3)'}
                                    </p>
                                </ScrollAnimation>
                            </div>
                            <div className='item'>
                                <ScrollAnimation style={'toLeft'}>
                                    <LoadedImage
                                        src={[babyBurger]}
                                        blur={babyBurger_blur}
                                        alt={'BABY BURGER'}
                                    />
                                </ScrollAnimation>
                                <ScrollAnimation style={'up'}>
                                    <h3>
                                        {locale === 'sk'
                                            ? 'BABY BURGER'
                                            : 'BABY BURGER'}
                                    </h3>
                                    <p className='price'>8.20€</p>
                                    <p>
                                        {locale === 'sk'
                                            ? 'Žemľa, hamburgerová omáčka, 90g hovädzie mäso, 1ks cheddar, paradajka, kyslá uhorka al:1,3,7,10'
                                            : 'Bun, hamburger sauce, 90g beef meat, 1 slice of cheddar, tomato, pickle al:1,3,7,10'}
                                    </p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>

                <div id='dezerty' className='dezerty'>
                    <div className='title'>
                        <h2>{locale === 'sk' ? 'DEZERTY' : 'DESSERTS'}</h2>
                        <p>
                            {locale === 'sk'
                                ? 'DEZERT PODĽA PONUKY'
                                : 'DESSERT ACCORDING TO THE OFFER'}
                            <br /> 100G - 3.40€
                        </p>
                    </div>
                    <div className='content'>
                        <ScrollAnimation style={'toRight'}>
                            <LoadedImage
                                src={[sufle]}
                                blur={sufle_blur}
                                alt={'suflé s karamelom'}
                            />
                            <div>
                                <h3>
                                    {locale === 'sk'
                                        ? 'SUFLÉ S KARAMELOM'
                                        : 'CARAMEL SOUFFLÉ'}
                                </h3>
                                <p className='price'>3.60€</p>
                                <p>
                                    {locale === 'sk'
                                        ? 'Podávane s mangovým čatní a šľahačkou 90g (1,3,7)'
                                        : 'Served with mango chutney and whipped cream 90g (1,3,7)'}
                                </p>
                            </div>
                        </ScrollAnimation>
                        {/* <ScrollAnimation style={'toLeft'}>
                            <div>
                                <h3>
                                    {locale === 'sk'
                                        ? 'PALACINKY PLNENÉ ČOKOLÁDOU'
                                        : 'CHOCOLATE FILLED PANCAKES'}
                                </h3>
                                <p className='price'>4.50€</p>
                                <p>
                                    {locale === 'sk'
                                        ? 'Podávané s jahodovým topingom a šľahačkou 150g (1,3,7)'
                                        : 'Served with strawberry topping and whipped cream 150g (1,3,7)'}
                                </p>
                            </div>
                            <LoadedImage
                                src={[palacinky]}
                                blur={palacinky_blur}
                                alt={'palacinky plnené čokoládou'}
                            />
                        </ScrollAnimation> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default MenuPage
